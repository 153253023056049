<template>
    <div class="view pt20 pl15 pr15 posre" v-loading="loading">
        <div class="flex-a-b-c">
            <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList">
                <i class="el-icon-office-building fs12 mr5"></i>
                <span class="fs13">{{ checkedCompany.companyName }}</span>
                <i class="el-icon-caret-bottom  fs12 ml5"></i>
            </div>
            <div class="flex-a-c">
                <el-button-group>
                    <el-button type="primary" class="wordBtn" :disabled="!checkedCompany.pcId"
                        @click="showExportData()">批量导出</el-button>
                    <el-button type="primary" class="wordBtn" :disabled="!checkedCompany.pcId"
                        :loading="exportWordLoading" @click="getProjectRulesData(true)">导出</el-button>
                </el-button-group>
                <el-button class="ml10" type="primary" v-if="hideBtn.indexOf(checkedIndex) == -1"
                    :disabled="!checkedCompany.pcId" :loading="submitLoading" @click="submit()">保存</el-button>
                <el-button type="success" icon="el-icon-refresh-right" :disabled="!checkedCompany.pcId"
                    @click="updateData()" title="更新数据"></el-button>
            </div>
        </div>
        <el-menu :default-active="year" class="projectAdmin" mode="horizontal" @select="selectYearFun">
            <el-menu-item :index="String(y)" v-for="(y, index) in yearData" :key="index"><span
                    :class="{ 'checkedYear': fileDay.indexOf(y) != -1 }"> {{ String(y) + '年'
                    }}</span></el-menu-item>
        </el-menu>
        <!-- <div>
            <el-tabs class="institution1" v-model="checkedIndex" @tab-click="getProjectRulesData()">
                <el-tab-pane :label="row.label" :name="id" v-for="(row, id) in typeData" :key="id"></el-tab-pane>
            </el-tabs>
        </div> -->
        <div class="d-flex justify-content-between">
            <div class="posre el-menu-vertical-demo">
                <el-menu class="institutionMenu scrollbar_none"
                    :style="{ overflowY: 'scroll', height: contentHeight + 'px' }" :default-active="checkedIndex"
                    :collapse="isCollapse" @select="checkedTypeFun">
                    <el-menu-item :index="String(index)" v-for="(row, index) in typeData" :key="index">
                        <i class="fwbold">
                            {{ row.id }}
                        </i>
                        <span slot="title" :title="row.label">{{ row.label }}</span>
                    </el-menu-item>
                </el-menu>
                <div class="btnArr">
                    <el-button type="primary" icon="el-icon-s-operation" @click="isCollapse = !isCollapse"></el-button>
                </div>
            </div>
            <div class="flex-j-c" :class="{ 'contentWidth': isCollapse }"
                :style="{ overflowY: 'scroll', height: contentHeight + 'px' }">
                <!-- 组织管理制度内容 -->
                <component :is="typeData[checkedIndex].companyName" ref="institution" :yearValue="year"
                    :wordData="wordData" :isAgain="isAgain" />
            </div>
        </div>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>

        <el-dialog title="导出组织管理指定" :close-on-click-modal="false" :modal-append-to-body="false"
            :visible.sync="showExport" width="600px">
            <el-tree :props="props" :data="treeData" show-checkbox @check="handleNodeClick" default-expand-all>
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showExport = false">取 消</el-button>
                <el-button type="primary" @click="exportWord()" :loading="exportWordLoading">获取导出文档</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectRulesData, setProjectRulesData, updateProjectRulesData } from "@/api/projectAdmin";
export default {
    name: "institution3",
    components: {
        selectCompanyList,
    },
    data() {
        return {
            isCollapse: false,
            collapseType: ["1"],
            checkedCompany: {},
            checkedIndex: "0",
            year: "",
            hideBtn: [0],
            typeData: [
                { companyName: "institution930", id: "9.3.0", label: "科技成果转化的组织实施与激励奖励制及创新创业平台材料审查目录" },
                { companyName: "institution9310", id: "9.3.1.0", label: "技术创新及科技成果转化奖励制度" },
                { companyName: "institution93102", id: "9.3.1.0-2", label: "技术创新及科技成果转化奖励制度-发布" },
                { companyName: "institution93111", id: "9.3.1.1-1", label: "科技成果转化奖励申请-1" },
                { companyName: "institution93112", id: "9.3.1.1-2", label: "科技成果转化奖励申请-2" },
                { companyName: "institution93121", id: "9.3.1.2-1", label: "科技成果转化奖励签收单-1" },
                { companyName: "institution93122", id: "9.3.1.2-2", label: "科技成果转化奖励签收单-2" },
                { companyName: "institution93202", id: "9.3.2.0-2", label: "知识产权管理与激励制度-发布" },
                { companyName: "institution9320", id: "9.3.2.0", label: "知识产权管理与激励制度" },
                { companyName: "institution93211", id: "9.3.2.1-1", label: "知识产权激励申请-1" },
                { companyName: "institution93212", id: "9.3.2.1-2", label: "知识产权激励申请-2" },
                { companyName: "institution9322", id: "9.3.2.2", label: "知识产权奖励凭证" },
                { companyName: "institution93231", id: "9.3.2.3-1", label: "知识产权激励签收单-1" },
                { companyName: "institution93232", id: "9.3.2.3-2", label: "知识产权激励签收单-2" },
                { companyName: "institution9332", id: "9.3.3-2", label: "项目孵化管理办法及实施细则-发布" },
                { companyName: "institution933", id: "9.3.3", label: "项目孵化管理办法及实施细则" },
                { companyName: "institution93402", id: "9.3.4.0-2", label: "员工创新提案与奖励管理办法-发布" },
                { companyName: "institution9340", id: "9.3.4.0", label: "员工创新提案与奖励管理办法" },
                { companyName: "institution9341", id: "9.3.4.1", label: "附表员工创新提案与奖励申请表" },
            ],
            yearData: [],
            formData: {},
            formType: "",
            updateId: 0,
            loading: false,
            exportWordLoading: false,
            submitLoading: false,
            wordData: {},
            showExport: false,
            props: {
                label: 'label',
                children: 'children'
            },
            treeData: [],
            checkedTree: [],
            contentHeight: 500,
            fileDay: "",
            isAgain: false,
        };
    },
    mounted() {
        //获取年份
        this.getYearData();
        this.contentHeight = Number($(".content").height()) - 120;
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
            return;
        }
        if (!checkedCompany.fileDay) {
            checkedCompany.fileDay = "";
        } else {
            let oneDay = 1000 * 60 * 60 * 24;
            checkedCompany.fileDay = new Date(new Date(checkedCompany.fileDay).getTime() + (oneDay * 30)).toLocaleDateString().replace(/\//ig, "-");
            this.fileDay = checkedCompany.fileDay;
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.$set(this.wordData, 'companyData', this.checkedCompany)
        let treeData = [];
        for (let i in this.typeData) {
            treeData.push({ id: this.typeData[i].id, label: this.typeData[i].label });
        }
        this.treeData = [{ label: "组织管理制度9.3", children: treeData }];
        //获取制度
        this.getProjectRulesData();
    },
    methods: {
        /**@method 更新数据 */
        updateData() {
            this.$confirm(`此操作将更新${this.year}年管理制度的日期、法人、打印人, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let codeStr = [];
                for (let row of this.typeData) {
                    codeStr.push(row.id);
                }
                updateProjectRulesData({
                    year: this.year,
                    pcId: this.checkedCompany.pcId,
                    codeStr: codeStr.join(","),
                }).then(res => {
                    if (res.code === 200) {
                        this.$message.success("数据更新完成！");
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code != 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        checkedTypeFun(index) {
            this.checkedIndex = index;
            this.getProjectRulesData();
        },
        /**@method 选中导出文件 */
        handleNodeClick(data, checkedData) {
            let checkedTree = [];
            for (let row of checkedData.checkedNodes) {
                if (!('children' in row)) {
                    checkedTree.push(row);
                }
            }
            this.checkedTree = checkedTree;
        },
        /**@method 获取导出路径 */
        exportWord() {
            let codeStr = [];
            for (let row of this.checkedTree) {
                codeStr.push(row.id);
            }
            this.getProjectRulesData(true, codeStr.join(","), true);
        },
        /**@method 下载 */
        dowloadWord(url) {
            let link = document.createElement('a');
            link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + url;
            link.click();
        },
        showExportData() {
            this.showExport = true;
        },
        selectYearFun(year) {
            this.year = year;
            this.getProjectRulesData();
        },
        /**@method 获取制度数据/导出 */
        getProjectRulesData(isDownload, codeStr, isZip) {
            if (!this.checkedCompany.pcId) {
                return;
            }
            let params = {
                codeStr: codeStr ? codeStr : this.typeData[this.checkedIndex].id,
                year: this.year,
                isAgain: this.isAgain,
                pcId: this.checkedCompany.pcId,
            };
            if (isDownload) {
                if (isZip) {
                    params.isZip = isZip;
                }
                params.isDownload = true;
                this.exportWordLoading = true;
            } else {
                this.loading = true;
                if (this.fileDay.indexOf(this.year) != -1) {
                    this.checkedCompany.fileDay = this.fileDay;
                } else {
                    this.checkedCompany.fileDay = "";
                }
            }
            this.wordLoading = true;
            getProjectRulesData(params).then(res => {
                if (isDownload) {
                    this.exportWordLoading = false;
                } else {
                    this.loading = false;
                }
                if (res.data) {
                    if (isDownload) {
                        this.dowloadWord(res.data[0]);
                    } else {
                        let obj = {
                            ...res.data[0],
                            companyData: this.checkedCompany
                        }
                        this.wordData = obj
                        if ('fileDay' in this.wordData && this.wordData.fileDay) {
                            this.wordData.fileDay = this.wordData.fileDay.replace(/\//ig, "-");
                        }
                    }
                } else {
                    this.wordData = {
                        companyData: this.checkedCompany
                    };

                }
            }).catch(err => {
                this.wordData = {
                    companyData: this.checkedCompany
                };
                if (isDownload) {
                    this.exportWordLoading = false;
                } else {
                    this.loading = false;
                }

            })
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.yearData = yearData;
            this.year = String(this.yearData[0])
        },
        /**@method 显示选择研发企业 */
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        /**@method 选择研发企业 */
        selectCompany(row) {
            if (!row.fileDay) {
                row.fileDay = "";
            } else {
                let oneDay = 1000 * 60 * 60 * 24;
                this.fileDay = new Date(new Date(row.fileDay).getTime() + (oneDay * 30)).toLocaleDateString().replace(/\//ig, "-");
                row.fileDay = this.fileDay
            }
            this.checkedCompany = row;
            this.$set(this.wordData, 'companyData', row);

            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.getProjectRulesData();
        },
        /**@method 保存 */
        submit() {
            let content = {
                ...this.$refs.institution.formData
            }
            for (let key in content) {
                if (content[key] && (key.indexOf("time") != -1 || key.indexOf("Day") != -1)) {
                    let time = new Date(content[key]);
                    let yyyy = time.getFullYear();
                    let mm = time.getMonth() + 1;
                    let dd = time.getDate();
                    mm = mm < 10 ? "0" + mm : mm;
                    dd = dd < 10 ? "0" + dd : dd;
                    content[key] = yyyy + "-" + mm + "-" + dd;
                }
                if (content.content) {
                    for (let row of content.content) {
                        for (let childKey in row) {
                            if (row[childKey] && (childKey.indexOf("time") != -1 || childKey.indexOf("Day") != -1)) {
                                let time = new Date(row[childKey]);
                                let yyyy = time.getFullYear();
                                let mm = time.getMonth() + 1;
                                let dd = time.getDate();
                                mm = mm < 10 ? "0" + mm : mm;
                                dd = dd < 10 ? "0" + dd : dd;
                                row[childKey] = yyyy + "-" + mm + "-" + dd;
                            }
                        }
                    }
                }
            }
            let params = {
                code: this.typeData[this.checkedIndex].id,
                year: this.year,
                isAgain: this.isAgain,
                pcId: this.checkedCompany.pcId,
                content: content
            }
            this.submitLoading = true;
            setProjectRulesData(params).then(res => {
                this.submitLoading = false;
                if (res.code === 200) {
                    this.$message.success("保存完成")
                    this.getDetails();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.submitLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },

    },
};
</script>
<style lang="scss">
.institutionMenu.el-menu--collapse {
    width: 100px !important;
}

.institution1 {
    .el-tabs__item {
        padding: 0 15px !important;
        width: auto !important;
        height: 56px !important;
        line-height: 56px !important;
        text-align: center !important;
    }
}
</style>
<style lang="scss" scoped>
.projectAdmin {
    /deep/.el-menu-item.is-active::after {
        background: transparent !important;
    }

    .checkedYear {
        color: #3a78ef !important;
        font-weight: bold;
    }
}

.contentWidth {
    width: calc(100% - 110px)
}

.btnArr {
    position: absolute;
    right: -45px;
    top: 0px;
    z-index: 2;
}

.el-menu-vertical-demo {
    max-width: calc(100% - 1150px);
    min-width: 100px;
}

.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}
</style>